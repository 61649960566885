import { GET_FREE_TIME_ERROR_CODE } from "@sellernote/_shared/src/constants/forwarding/error/freeTime";
import EmptyRequestIcon from "@sellernote/_sds-v2/src/components/svgIcons/EmptyRequestIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Styled from "./index.styles";

function ErrorGuide({ errorMessage }: { errorMessage?: string }) {
  return (
    <Styled.errorGuideContainer>
      <EmptyRequestIcon width={32} height={32} color={COLOR.grayScale_600} />

      <span className="error-message">
        {errorMessage ?? GET_FREE_TIME_ERROR_CODE["default"].title}
      </span>
    </Styled.errorGuideContainer>
  );
}

export default ErrorGuide;
